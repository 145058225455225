import { apiRequest } from "../apiRequest/apiRequest";

export const productApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createProfitLinkFromShortLink: builder.mutation({
      query: (data) => {
        const { access_token, uID, shortLink, localUniqueId } = data || {};
        return {
          url: `/create-profitlink`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: { shortLink, uID, localUniqueId },
        };
      },
      invalidatesTags: [""],
    }),
  }),
});

export const {
  useCreateProfitLinkMutation,
  useCreateProfitLinkForShareMutation,
  useCreateProfitLinkFromShortLinkMutation,
} = productApiSlice;
