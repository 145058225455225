import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import rocketGif from "../Assets/images/rocket.gif";
import { useCreateProfitLinkFromShortLinkMutation } from "../features/profitlink/profitlinkApiSlice";
// import "./Home.css";

const EmptyPage = () => {
  const { ak } = useParams();
  const access_token = Cookies?.get("ayykori");

  const [
    createProfitLinkFromShortLink,
    {
      data: dataLink,
      isLoading: isLoadingLink,
      isError: isErrorLink,
      isSuccess: isSuccessLink,
      error: errorLink,
    },
  ] = useCreateProfitLinkFromShortLinkMutation() || {};

  //  getting profit link from shortlink
  useEffect(() => {
    let uID = ak?.split("_")[1];
    let localId = localStorage.getItem("uID");
    if (uID === localId) {
      console.log("local exist");
      createProfitLinkFromShortLink({
        access_token,
        uID,
        localUniqueId: localId,
      });
    }
    if (uID !== localId) {
      console.log("local does not exist");
      localStorage.setItem("uID", uID);
      createProfitLinkFromShortLink({
        access_token,
        uID,
      });
    }
  }, []);

  // redirecting it to the shortLink
  useEffect(() => {
    if (isSuccessLink) {
      window.location.href = dataLink?.profitLink;
    }
  }, [isSuccessLink]);

  // showing error
  useEffect(() => {
    if (isErrorLink) {
      toast.error("Something Went Wrong!!");
    }
  }, [isErrorLink]);

  return (
    <div>
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col justify-center items-center">
          <img
            className="w-28 md:w-32 h-28 md:h-32 mx-auto"
            src={rocketGif}
            alt=""
          />
          <h1 className="text-2xl custom-font text-center text-gray-800 font-semibold mt-5">
            Sit tight! We're gonna grab <br />
            <span className="text-[#33D9D9] px-2 uppercase">
              the best deals
            </span>
            for you!
          </h1>
        </div>
      </div>
    </div>
  );
};
export default EmptyPage;
