import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { store } from "./app/store";
import EmptyRedirectPage from "./pages/EmptyRedirectPage";
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routes>
          <Route path="/:ak" element={<EmptyRedirectPage />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
